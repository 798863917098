const CognitoConfig = {
    "aws_project_region":process.env.REACT_APP_AWS_REGION,
    "aws_cognito_identity_pool_id": process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID,
    "aws_cognito_region": process.env.REACT_APP_AWS_REGION,
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_USER_POOLS_ID,
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_USER_POOLS_WEB_CLIENT_ID,
    "aws_cognito_signup_attributes": [
        process.env.REACT_APP_COGNITO_SIGNUP_ATRRIBUTES
    ],
   };

export default CognitoConfig;
