/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import Controls from '../controls/Controls';
import { useForm, Form } from './useForm';

const booleanTypes = [
  { id: false, title: 'false' },
  { id: true, title: 'true' },
];

const initialFValues = {
  objectName: '',
  objectValue: '',
  listOfValues: [],
  description: '',
  objectType: '',
  objectGroup: '',
  accessControl: '',
};

export default function EditAttributeForm(props) {
  console.log('EditAttributeForm():Start');
  const { addOrEdit, recordForEdit, resolve } = props;

  const [submitDisabled, setSubmitDisabled] = useState(true);

  const backgroundColorStyle = {
    backgroundColor: '#fefefe',
  };

  const validate = (fieldValues = values) => {
    console.log(
      'EditAttributeForm(): fieldValues:' + JSON.stringify(fieldValues)
    );
    console.log(
      'EditAttributeForm(): fieldValues.objectName:' + fieldValues.objectName
    );

    let temp = { ...errors };
    if ('objectName' in fieldValues) {
      temp.objectName = fieldValues.objectName ? '' : 'This field is required.';
    }
    if ('objectValue' in fieldValues) {
      if (fieldValues.objectValue) {
        if (fieldValues.objectValue !== String(recordForEdit.objectValue)) {
          temp.objectValue = '';
          setSubmitDisabled(false);
        } else {
          temp.objectValue = 'This field is unmodified.';
          setSubmitDisabled(true);
        }
      } else {
        temp.objectValue = 'This field is required.';
        setSubmitDisabled(true);
      }
    }
    if ('objectType' in fieldValues) {
      temp.objectType = fieldValues.objectType ? '' : 'This field is required.';
    }
    if ('objectGroup' in fieldValues) {
      temp.objectGroup = fieldValues.objectGroup
        ? ''
        : 'This field is required.';
    }
    if ('accessControl' in fieldValues) {
      temp.accessControl = fieldValues.accessControl
        ? ''
        : 'This field is required.';
    }

    setErrors({
      ...temp,
    });

    {
      /*
            if ('departmentId' in fieldValues)
                temp.departmentId = fieldValues.departmentId.length != 0 ? "" : "This field is required."
            setErrors({
                ...temp
            })
        */
    }

    if (fieldValues === values) {
      return Object.values(temp).every((x) => x === '');
    }
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialFValues, true, validate);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      addOrEdit(values, resetForm, resolve);
    }
  };

  useEffect(() => {
    if (recordForEdit != null) {
      console.log('recordForEdit:' + JSON.stringify(recordForEdit));
    }

    setValues({
      ...recordForEdit,
    });
  }, [recordForEdit]);

  return (
    <Form onSubmit={handleSubmit} style={backgroundColorStyle}>
      <Grid container style={backgroundColorStyle}>
        <Grid item xs={12} style={backgroundColorStyle}>
          <Controls.Input
            name="objectName"
            label="AttributeName"
            InputProps={{ readOnly: true }}
            value={values.objectName}
            onChange={handleInputChange}
            error={errors.objectName}
          />

          {values.objectType === 'string' && (
            <Controls.Input
              name="objectValue"
              label="Attribute Value"
              color="secondary"
              focused
              multiline
              value={values.objectValue}
              onChange={handleInputChange}
              error={errors.objectValue}
            />
          )}
          {values.objectType === 'number' && (
            <Controls.Input
              name="objectValue"
              label="Attribute Value"
              color="secondary"
              focused
              type="number"
              value={values.objectValue}
              onChange={handleInputChange}
              error={errors.objectValue}
            />
          )}
          {values.objectType === 'boolean' && (
            <Controls.RadioGroup
              name="objectValue"
              label="Attribute Value"
              color="secondary"
              focused
              value={values.objectValue}
              onChange={handleInputChange}
              items={booleanTypes}
            />
          )}
          {values.objectType === 'list' && (
            <Controls.Select
              name="objectValue"
              label="Attribute Value"
              color="secondary"
              focused
              InputProps={{ readOnly: true }}
              value={values.objectValue}
              onChange={handleInputChange}
              options={values.listOfValues}
              error={errors.objectValue}
            />
          )}
          {values.objectType === 'date' && (
            <Controls.DatePicker
              name="objectValue"
              label="Attribute Value"
              color="secondary"
              focused
              value={values.objectValue}
              onChange={handleInputChange}
              error={errors.objectValue}
            />
          )}
          {values.objectType !== 'string' &&
            values.objectType !== 'number' &&
            values.objectType !== 'boolean' &&
            values.objectType !== 'list' &&
            values.objectType !== 'date' && (
              <Controls.Input
                name="objectValue"
                label="Attribute Value (CONFIGURATION ERROR, MISSING ATTRIBUTE DEFINITION!)"
                color="secondary"
                focused
                multiline
                value={values.objectValue}
                onChange={handleInputChange}
                error={errors.objectValue}
              />
            )}

          <Controls.Input
            name="objectGroup"
            label="Attribute Type"
            InputProps={{
              readOnly: true,
            }}
            value={values.objectType}
            onChange={handleInputChange}
            error={errors.objectGroup}
          />
          {/* <Controls.Select
                        name="objectType"
                        label="Attribute Type"
                        InputProps={{
                            readOnly: true,
                          }}
                        value={values.objectType}
                        onChange={handleInputChange}
                        options={AttributeService.getObjectTypeCollection()}
                        error={errors.objectType}
                    />
                   */}
          <Controls.Input
            name="objectGroup"
            label="Attribute Group"
            InputProps={{
              readOnly: true,
            }}
            value={values.objectGroup}
            onChange={handleInputChange}
            error={errors.objectGroup}
          />
          <Controls.Input
            name="accessControl"
            label="Access Control"
            InputProps={{
              readOnly: true,
            }}
            value={values.accessControl}
            onChange={handleInputChange}
            error={errors.objectGroup}
          />
          <div>
            <Controls.Button
              disabled={submitDisabled}
              type="submit"
              text="Submit"
            />
          </div>
        </Grid>
      </Grid>
    </Form>
  );
}
