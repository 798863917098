/* eslint-disable no-template-curly-in-string */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@mui/styles';
import { Drawer, Typography, Toolbar } from '@mui/material';
import { useNavigate, useLocation, Outlet, Link } from 'react-router-dom';
import CognitoConfig from '../services/cognito-config.service';
import { Amplify, API } from 'aws-amplify';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import { Authenticator, Heading } from '@aws-amplify/ui-react';
import Button from '@mui/material/Button';
import '@aws-amplify/ui-react/styles.css';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import CssBaseline from '@mui/material/CssBaseline';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Divider from '@mui/material/Divider';
import connectEA_API from '../services/connectea-api.service';
import { UserDetailsContext } from '../context/AuthContext';
import { removeCookie } from '../services/browserStorage.service';

Amplify.configure(CognitoConfig);

const drawerWidth = 220;

//RN sidebar design -Start 12-14-2022
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

//RN sidebar design -End 12-14-2022

const useStyles = makeStyles((theme) => {
  return {
    page: {
      background: '#f9f9f9',
      width: '100%',
      padding: theme.spacing(3),
    },
    root: {
      display: 'flex',
    },
    drawer: {
      width: drawerWidth,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    active: {
      background: '#f4f4f4',
    },
    title: {
      padding: theme.spacing(2),
    },
    align: {
      float: 'right',
    },
    appBar: {
      width: 'calc(100% - ${drawerWidth}px)',
      marginLeft: drawerWidth,
      //background: '#fefefe',
      background: '#2E3B55',
      padding: theme.spacing(0),
    },
    date: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
  };
});

export default function Layout({ children }) {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setAuth(event.target.checked);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const [objTreeViewDataFirstRender, setObjTreeViewDataFirstRender] = useState(
    []
  );

  const [objTreeViewData, setObjTreeViewData] = useState([]);
  var v_objTreeViewDataList = null;

  useEffect(() => {
    (async () => {
      const resObjTreeViewData = await connectEA_API().get(
        '/configuration/tree'
      );
      console.log('configuration/tree(): Start');
      localStorage.setItem(
        'resObjTreeViewData',
        JSON.stringify(resObjTreeViewData.data)
      );
      setObjTreeViewDataFirstRender([...resObjTreeViewData.data]);
      setObjTreeViewData([...objTreeViewData, ...resObjTreeViewData.data]);
      v_objTreeViewDataList = JSON.parse(
        localStorage.getItem('resObjTreeViewData')
      );
      console.log(
        'Layout() resObjTreeViewData: ' +
          JSON.stringify(resObjTreeViewData.data)
      );
    })();
  }, []);

  function filterBy(arr, query) {
    return query
      ? arr.reduce((acc, item) => {
          if (item.children?.length) {
            const filtered = filterBy(item.children, query);
            if (filtered.length)
              return [...acc, { ...item, children: filtered }];
          }

          const { children, ...itemWithoutChildren } = item;
          return item.name?.toLowerCase().includes(query.toLowerCase())
            ? [...acc, itemWithoutChildren]
            : acc;
        }, [])
      : arr;
  }

  const onQueryChange = (e) => {
    setObjTreeViewData(filterBy(objTreeViewDataFirstRender, e.target.value));
  };

  const getNodeIdsToExpand = (treeItems) => {
    let nodeIdsToExpand = [];

    if (treeItems && treeItems[0]) {
      //Always expand the first node if the tree is loaded
      nodeIdsToExpand.push(treeItems[0].id);
    } else {
      return [];
    }

    //Any selected objectId would have been saved in local storage by now. We will use it to determine nodeIds to expand
    var objectId = JSON.parse(localStorage.getItem('objectId'));
    if (!objectId || objectId === '/') {
      //No objectId was selected
      return nodeIdsToExpand;
    }

    const nodesToExpand = objectId.split('/');
    let previousNode = '';
    let treeItem = treeItems[0];
    for (var nodeToExpand of nodesToExpand) {
      if (nodeToExpand) {
        previousNode += '/' + nodeToExpand;

        for (var treeItemChild of treeItem.children) {
          if (treeItemChild.id === previousNode) {
            treeItem = treeItemChild;
            if (treeItem.children) {
              nodeIdsToExpand.push(previousNode);
            }
            break;
          }
        }
      }
    }

    return nodeIdsToExpand;
  };

  const getTreeItemsFromData = (treeItems) => {
    return treeItems.map((treeItemData) => {
      let children = undefined;
      if (treeItemData.children && treeItemData.children.length > 0) {
        children = getTreeItemsFromData(treeItemData.children);
      }

      return (
        <Link
          to={`objectConfig${treeItemData.id}`}
          style={{ textDecoration: 'none' }}
        >
          <TreeItem
            key={treeItemData.id}
            nodeId={treeItemData.id}
            label={treeItemData.name}
            children={children}
            onClick={() => updateNodeInLocalStorage(treeItemData.id)}
          />
        </Link>
      );
    });
  };

  const DataTreeView = ({ treeItems }) => {
    return (
      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={getNodeIdsToExpand(treeItems)}
      >
        {getTreeItemsFromData(treeItems)}
      </TreeView>
    );
  };

  //This is a function that protects against wrong configurations (as seen during development, sometimes the tree item id is not a valid branch..)
  const verifyNodeIsValidBranch = (treeItem, nodeToVerify) => {
    if (treeItem.id === nodeToVerify && treeItem.children) {
      return true;
    }

    for (var treeItemChild of treeItem.children) {
      if (treeItemChild.children) {
        if (verifyNodeIsValidBranch(treeItemChild, nodeToVerify)) {
          return true;
        }
      }
    }
    return false;
  };

  const updateNodeInLocalStorage = (v_path) => {
    console.log('updateNodeInLocalStorage(): Start');

    var objectId = JSON.parse(localStorage.getItem('objectId'));
    if (objectId && objectId === v_path) {
      //The user clicked the node that was previously expanded.
      //We need to set the previous node in local storage so the current node can be unselected upon refresh
      const posLastSlash = v_path.lastIndexOf('/');
      let parent_v_path = v_path.substring(0, posLastSlash);
      if (!parent_v_path) {
        parent_v_path = '/';
      }
      localStorage.setItem('objectId', JSON.stringify(parent_v_path));
    } else if (verifyNodeIsValidBranch(objTreeViewData[0], v_path)) {
      localStorage.setItem('objectId', JSON.stringify(v_path));
    }

    console.log('updateNodeInLocalStorage(): End');
  };

  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  const menuItems = [
    {
      text: 'Object Config',
      icon: <QueueOutlinedIcon color="secondary" />,
      path: '/',
    },

    {
      text: 'Audit Management',
      icon: <SettingsOutlinedIcon color="secondary" />,
      path: '/audit',
    },

    /*
    { 
      text: 'Data Migration', 
      icon: <DriveFileMoveIcon color="secondary" />, 
      path: '/dataMigration' 
    },*/
  ];

  const userDetails = useContext(UserDetailsContext);

  return (
    <div className={classes.root}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Connect Elastic Admin(EA)
            </Typography>
            <main>
              <div>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  <AccountCircle />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    User: {userDetails?.username}
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    {' '}
                    <Button
                      color="secondary"
                      onClick={() => {
                        removeCookie('ad_session');
                        window.location.href =
                          'https://login.windows.net/ddc9d650-4c18-49ea-b451-3170131fe383/oauth2/logout';
                      }}
                    >
                      Sign out
                    </Button>
                  </MenuItem>
                </Menu>
              </div>
            </main>
          </Toolbar>
        </AppBar>

        {/* side drawer */}
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'ltr' ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <input placeholder="Filter by..." onChange={onQueryChange} />

          <DataTreeView treeItems={objTreeViewData} />
        </Drawer>

        {/* main content */}
        <div className={classes.page}>
          <div className={classes.toolbar}></div>
          {children}
          <Outlet />
        </div>
      </Box>
    </div>
  );
}
