import axios from 'axios';
import { getCookie } from './browserStorage.service.js';

const commonHeaders = {
  'Content-Type': 'application/json',
};

const excludedPaths = ['/api/login', '/api/callback'];
const JET_BLUE_SESSION_COOKIE = 'ad_session';

const isPathExcluded = (path, excludedPaths) => {
  return path
    ? excludedPaths.some((excludedPath) => path.startsWith(excludedPath))
    : false;
};

axios.interceptors.request.use(
  (request) => {
    if (!isPathExcluded(request.url, excludedPaths)) {
      const adSessionToken = getCookie(JET_BLUE_SESSION_COOKIE);
      if (adSessionToken.isExists) {
        request.headers['Authorization'] = `Bearer ${encodeURIComponent(
          adSessionToken.value
        )}`;
      }
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const httpGetService = async ({ path, headers, params }) => {
  try {
    const config = {
      headers: { ...commonHeaders, ...headers },
      withCredentials: true,
      params,
    };

    const response = await axios.get(path, config);
    return response;
  } catch (error) {
    throw error;
  }
};
