import React from 'react';
import { KeyboardDatePicker } from "@material-ui/pickers";

export default function DatePicker(props) {

    const { name, label, value, onChange } = props;

    const convertToDefEventPara = (name, value) => ({
        target: {
            name, value
        }
    })

    return (
        <KeyboardDatePicker
            autoOk
            variant="inline"
            inputVariant="outlined"
            label={label}
            format="MM/dd/yyyy"
            value={value}
            InputAdornmentProps={{ position: "start" }}
            onChange={date => onChange(convertToDefEventPara(name, date))}
        />
    )
}
