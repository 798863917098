/* eslint-disable import/no-anonymous-default-export */
import axios from 'axios';
import { getCookie } from './browserStorage.service';

// const ACCESS_TOKEN_KEY = 'ACCESS_TOKEN';
const AD_SESSION = 'ad_session';

export default (history = null) => {
  const baseURL = process.env.REACT_APP_CONNECT_EA_API;

  let headers = {};

  // if (sessionStorage.getItem(ACCESS_TOKEN_KEY)) {
  //   headers.Authorization = `Bearer ${sessionStorage.getItem(
  //     ACCESS_TOKEN_KEY
  //   )}`;
  // }

  headers.Authorization = `Bearer ${getCookie(AD_SESSION).value}`;

  const connectEA_API = axios.create({
    baseURL: baseURL,
    headers,
  });

  connectEA_API.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),
    (error) => {
      if (!error.response) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      if (error.response.status === 403) {
        localStorage.removeItem('token');

        if (history) {
          history.push('/auth/login');
        } else {
          window.location = '/auth/login';
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }
    }
  );

  return connectEA_API;
};
