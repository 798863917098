import Cookies from 'js-cookie';

export const getCookie = (cookieName) => {
  const value = Cookies.get(cookieName);
  const valObj = { value: null, isExists: false };
  if (value) {
    valObj.value = value.trim();
    valObj.isExists = true;
  }
  return valObj;
};

export const setCookie = (name, value) => {
  Cookies.set(name, value, {
    path: '/',
    secure: true,
    sameSite: 'none',
  });
};

export const removeCookie = (name, path, domain) => {
  Cookies.remove(name, {
    path,
    domain,
  });
};
