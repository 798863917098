/* eslint-disable react-hooks/exhaustive-deps */
import Loader from '../components/Loader.jsx';
import { createContext, useEffect, useState } from 'react';
import { httpGetService } from '../services/api.service.js';
import Unauthorized from '../components/Unauthorized.jsx';

export const UserDetailsContext = createContext(null);

const AuthContext = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const fetchUserDetails = async () => {
    try {
      const response = await httpGetService({
        path: `${window.location.origin}/api/v1/user/user-details`,
      });

      return response.data.data;
    } catch (error) {
      throw error;
    }
  };

  const getUserAuthDetails = async () => {
    try {
      const userInfo = await fetchUserDetails();
      setUserDetails(userInfo);
      setIsLoading(false);
      setIsAuthenticated(true);
    } catch (error) {
      setIsLoading(false);
      setIsAuthenticated(false);
    }
  };

  useEffect(() => {
    getUserAuthDetails();
  }, []);

  if (isLoading) {
    return <Loader />;
  } else if (!isLoading && isAuthenticated) {
    return (
      <UserDetailsContext.Provider value={userDetails}>
        {children}
      </UserDetailsContext.Provider>
    );
  } else {
    return <Unauthorized />;
  }
};

export default AuthContext;
