import React from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
  Route,
} from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { orange } from '@mui/material/colors';
import Layout from './adminlayout/layout';
import ErrorPage from './components/error-page';
import ConfigurationObjects from './components/configurationObjects.component';
import AuditManagementComponent from './components/auditManagement.component';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import AuthContext from './context/AuthContext';

const customTheme = createTheme({
  palette: {
    primary: {
      main: '#2E3B55',
    },
    secondary: orange,
  },
  typography: {
    fontFamily: 'Quicksand',
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 600,
    fontWeightBold: 700,
  },
});

function App() {
  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout />} errorElement={<ErrorPage />}>
        <Route path="/objectConfig" element={<ConfigurationObjects />} />
        <Route path="/objectConfig/*" element={<ConfigurationObjects />} />
        <Route path="/audit" element={<AuditManagementComponent />} />
      </Route>
    )
  );

  return (
    <AuthContext>
      <ThemeProvider theme={customTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <RouterProvider router={router} />
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </AuthContext>
  );
}

export default App;
