/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import ViewColumn from '@mui/icons-material/ViewColumn';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import { makeStyles } from '@mui/styles';
import RestoreIcon from '@mui/icons-material/Restore';
import { forwardRef } from 'react';
import MaterialTable from 'material-table';
import connectEA_API from '../services/connectea-api.service';

function AuditManagement() {
  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    RestoreIcon: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [auditList, setAuditList] = React.useState([]);

  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  const [selectedRow, setSelectedRow] = useState(null);

  var v_auditList = null;
  var reqTag = 'NA';

  const useStyles = makeStyles({
    colHeader: {
      color: '#FFF',
      '&:hover': {
        color: '#FFF',
      },
    },
  });

  const classes = useStyles();

  var auditListColumns = [
    { title: <p className={classes.colHeader}>AuditId</p>, field: 'auditId' },
    { title: <p className={classes.colHeader}>UserId</p>, field: 'userId' },
    { title: <p className={classes.colHeader}>BU</p>, field: 'tags' },
    { title: <p className={classes.colHeader}>Path</p>, field: 'path' },
    {
      title: <p className={classes.colHeader}>ChangeReason</p>,
      field: 'changeReason',
    },
    {
      title: <p className={classes.colHeader}>ChangeType</p>,
      field: 'changeType',
    },
    {
      title: <p className={classes.colHeader}>ChangedAt</p>,
      field: 'changedAt',
      sorting: true,
      defaultSort: 'desc',
    },
  ];

  useEffect(() => {
    getConfigTree();
  }, []);

  const getConfigTree = () => {
    (async () => {
      var v_userLobTag = localStorage.getItem('v_userLobTag');
      var v_accessControl = localStorage.getItem('v_accessControl');
      if (v_accessControl === 'Admin') {
        reqTag = 'all';
      } else if (
        v_accessControl.includes('AdminLite') ||
        v_accessControl.includes('Supervisor')
      ) {
        reqTag = v_userLobTag;
      }

      var reqBody = {
        tags: reqTag,
      };

      const rsltAuditList = await connectEA_API().post(
        '/audit/object',
        reqBody
      );
      setAuditList([...auditList, ...rsltAuditList.data]);
    })();
  };

  return (
    <div>
      <MaterialTable
        title="Audit Summary "
        columns={auditListColumns}
        data={auditList}
        icons={tableIcons}
        options={{
          actionsColumnIndex: -1,
          search: true,
          // grouping: true,
          // exportButton: true,
          headerStyle: {
            // backgroundColor: '#2E3B55',
            backgroundColor: '#536a82',
            color: '#FFF',
          },
          rowStyle: (rowData) => ({
            // backgroundColor: (selectedRow === rowData.tableData.id) ? '#F2F3F3' : '#FFF'
            backgroundColor:
              selectedRow === rowData.tableData.id ? '#F2F3F3' : '#FFF',
            color: selectedRow === rowData.tableData.id ? '#1C83C3' : '#0b2741',
          }),
        }}
      />
    </div>
  );
}

export default AuditManagement;
