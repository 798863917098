/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import Controls from '../controls/Controls';
import { useForm, Form } from './useForm';
import connectEA_API from '../../services/connectea-api.service.js';

export default function AddItemForm() {
  const [initialValues, setInitialValues] = useState({
    name: '',
    description: '',
    path: '',
  });
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [message, setMessage] = useState(null);

  const backgroundColorStyle = {
    backgroundColor: '#fefefe',
  };

  useEffect(() => {
    let parentPath = localStorage.getItem('v_objectParent');
    if (parentPath === '/') {
      parentPath = localStorage.getItem('v_path') || '/';
    }

    if (parentPath) {
      setInitialValues((prevValues) => ({
        ...prevValues,
        path: parentPath,
      }));
      setValues((prevValues) => ({
        ...prevValues,
        path: parentPath,
      }));
    }
  }, []);

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ('name' in fieldValues) {
      temp.name = fieldValues.name ? '' : 'This field is required.';
    }
    if ('description' in fieldValues) {
      temp.description = fieldValues.description
        ? ''
        : 'This field is required.';
    }
    if ('path' in fieldValues) {
      temp.path = fieldValues.path ? '' : 'This field is required.';
    }

    setErrors({ ...temp });

    if (Object.values(temp).every((x) => x === '')) {
      setIsSubmitDisabled(false);
    }

    return Object.values(temp).every((x) => x === '');
  };

  const { values, setValues, errors, setErrors, handleInputChange } = useForm(
    initialValues,
    true,
    validate
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      const parent = localStorage.getItem('v_objectParent');
      const newItem = {
        ...values,
        parent,
        attributes: {},
        inheritedAttributes: {},
      };

      setIsSubmitting(true);

      await connectEA_API()
        .put('/configuration/object', newItem)
        .then((res) => {
          console.log('handleAttributeRowUpdate(): Inside API.put() Start');
          setMessage(
            'Item added successfully, please reload page to see changes'
          );
        })
        .catch((error) => {
          console.log(error);
        });

      setIsSubmitting(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit} style={backgroundColorStyle}>
      <Grid container style={backgroundColorStyle}>
        <Grid item xs={12} style={backgroundColorStyle}>
          <Controls.Input
            name="name"
            label="Name"
            onChange={handleInputChange}
            error={errors.name}
          />
          <Controls.Input
            name="description"
            label="Description"
            onChange={handleInputChange}
            error={errors.description}
          />
          <Controls.Input
            name="path"
            label="Path"
            onChange={handleInputChange}
            error={errors.path}
            value={values.path}
          />
        </Grid>
        <Grid item xs={12}>
          {message && (
            <div
              style={{
                color: 'green',
                fontSize: '14px',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              {message}
            </div>
          )}
          {isSubmitting ? (
            <>Adding Item...</>
          ) : (
            <Controls.Button
              type="submit"
              text="Submit"
              disabled={isSubmitDisabled}
            ></Controls.Button>
          )}
        </Grid>
      </Grid>
    </Form>
  );
}
