/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable no-loop-func */
/* eslint-disable no-redeclare */
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import ViewColumn from '@mui/icons-material/ViewColumn';
import AddBox from '@mui/icons-material/AddBox';
import ArrowDownward from '@mui/icons-material/ArrowDownward';
import Check from '@mui/icons-material/Check';
import ChevronLeft from '@mui/icons-material/ChevronLeft';
import ChevronRight from '@mui/icons-material/ChevronRight';
import Clear from '@mui/icons-material/Clear';
import DeleteOutline from '@mui/icons-material/DeleteOutline';
import Edit from '@mui/icons-material/Edit';
import FilterList from '@mui/icons-material/FilterList';
import FirstPage from '@mui/icons-material/FirstPage';
import LastPage from '@mui/icons-material/LastPage';
import Remove from '@mui/icons-material/Remove';
import SaveAlt from '@mui/icons-material/SaveAlt';
import Search from '@mui/icons-material/Search';
import { styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';

import { forwardRef } from 'react';
import MaterialTable from 'material-table';
import Popup from '../adminlayout/popup';
import EditAttributeForm from './customForms/editAttributeForm';
import * as attributeService from '../services/attributes.service';
import format from 'date-fns/format';
import connectEA_API from '../services/connectea-api.service';
import AddItemForm from './customForms/addItemForm';
import { Button as MuiButton } from '@mui/material';

function ConfigurationObjects() {
  const location = useLocation();

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const [updateTitle, setUpdateTitle] = useState('');
  const [addTitle, setAddTitle] = useState('');
  const [attributeList, setAttributeList] = useState([]);
  const [allAttributeList, setAllAttributeList] = useState([]);
  const [addAttributeList, setAddAttributeList] = useState([]);
  const [inheritedAttributeList, setInheritedAttributeList] = useState([]);
  const [iserror, setIserror] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupAddItemToParent, setOpenPopupAddItemToParent] =
    useState(false);
  const [openAddAttributePopup, setOpenAddAttributePopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [records, setRecords] = useState(attributeService.getAllAttributes());

  var v_path = null;
  var v_objectName = '';
  var v_objectParent = '';
  var v_objectDescription = '';
  var v_changeReason = '';
  var v_prevAttributeData = {};
  var v_attributesbkp = {};
  var v_changeType = 'Add';
  const navigate = useNavigate();

  v_path = localStorage.getItem('v_path');

  // Accordion Start
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  // Accordion End

  var attributeListColumns = [
    { title: 'Name', field: 'objectName', sorting: true, defaultSort: 'asc' },
    { title: 'Value', field: 'objectValue' },
    { title: 'Inherited', field: 'objectInherited', editable: 'never' },
    { title: 'Type', field: 'objectType', editable: 'never' },
    { title: 'Group', field: 'objectGroup', editable: 'never' },
    { title: 'Access Control', field: 'accessControl', editable: 'never' },
  ];

  var addAttributeListColumns = [
    { title: 'Name', field: 'objectName', sorting: true, defaultSort: 'asc' },
    { title: 'Type', field: 'objectType', editable: 'never' },
    { title: 'Group', field: 'objectGroup', editable: 'never' },
    { title: 'Access Control', field: 'accessControl', editable: 'never' },
    { title: 'Description', field: 'description', editable: 'never' },
  ];

  useEffect(() => {
    if (location) {
      //Remove leading "/objectConfig" from the path name (that's just used for the url routing)
      v_path = location.pathname.substring('/objectConfig'.length);
      localStorage.setItem('v_path', v_path);

      if (!allAttributeList.length) {
        console.log(
          'ElasticAdminConfigManagement getting attribute definitions() - Start'
        );

        connectEA_API()
          .get('/attribute/definitions')
          .then((apiRes) => {
            console.log(
              'ElasticAdminConfigManagement attribute definitions():' +
                JSON.stringify(apiRes.data)
            );

            let allAttributes = [];
            if (apiRes) {
              for (var attribute of apiRes.data) {
                let attr = {
                  objectName: attribute.name,
                  objectType: attribute.type,
                  objectGroup: attribute.group,
                  accessControl: attribute.accessControl,
                  description: attribute.description,
                };

                if (attribute.values) {
                  attr.listOfValues = attribute.values.map((item) => ({
                    id: item,
                    title: item,
                  }));
                }

                allAttributes.push(attr);
              }
            }

            setAllAttributeList(allAttributes);

            getAttributeList(allAttributes);
          });
      } else {
        getAttributeList(allAttributeList);
      }
    }
  }, [location]);

  const getAttributeList = (allAttributes) => {
    console.log('getAttributeList() - Start');
    console.log('getAttributeList() - Calling Attribute API using:', v_path);

    connectEA_API()
      .get('/configuration/object', {
        params: {
          path: v_path,
        },
      })
      .then((rsltAttributeList) => {
        console.log(
          'ElasticAdminConfigManagement getAttributeList() - Results:' +
            JSON.stringify(rsltAttributeList.data)
        );

        v_objectName = rsltAttributeList.data.name;
        localStorage.setItem('v_objectName', v_objectName);
        v_objectParent = rsltAttributeList.data.parent;
        localStorage.setItem('v_objectParent', v_objectParent);
        v_objectDescription = rsltAttributeList.data.description;
        localStorage.setItem('v_objectDescription', v_objectDescription);

        setUpdateTitle('Update attributes for ' + rsltAttributeList.data.name);
        setAddTitle('Add attributes for ' + rsltAttributeList.data.name);

        let displayedAttributes = [];

        //Build an array of non-inherited Attributes
        let attributes = [];
        if (rsltAttributeList.data.attributes) {
          for (var rsltAttribute of Object.entries(
            rsltAttributeList.data.attributes
          )) {
            var attribute = {
              objectName: rsltAttribute[0],
              objectValue: rsltAttribute[1],
              objectInherited: false,
            };

            if (allAttributes) {
              //Complete the attribute properties using the properties found in allAttributes
              var matchingAttribute = allAttributes.find(
                (attr) => attr.objectName === attribute.objectName
              );
              if (matchingAttribute) {
                attribute.objectType = matchingAttribute.objectType;
                attribute.objectGroup = matchingAttribute.objectGroup;
                attribute.accessControl = matchingAttribute.accessControl;
                attribute.description = matchingAttribute.description;
                attribute.listOfValues = matchingAttribute.listOfValues;
              }
            }

            attributes.push(attribute);

            //Always display non-inherited attributes
            displayedAttributes.push(attribute);
          }
        }

        //Build an array of inherited attributes
        let inheritedAttributes = [];
        if (rsltAttributeList.data.inheritedAttributes) {
          for (var rsltInheritedAttribute of Object.entries(
            rsltAttributeList.data.inheritedAttributes
          )) {
            var inheritedAttribute = {
              objectName: rsltInheritedAttribute[0],
              objectValue: rsltInheritedAttribute[1],
              objectInherited: true,
            };

            if (allAttributes) {
              //Complete the inherited attribute properties using the properties found in allAttributes
              var matchingAttribute = allAttributes.find(
                (attr) => attr.objectName === inheritedAttribute.objectName
              );
              if (matchingAttribute) {
                inheritedAttribute.objectType = matchingAttribute.objectType;
                inheritedAttribute.objectGroup = matchingAttribute.objectGroup;
                inheritedAttribute.accessControl =
                  matchingAttribute.accessControl;
                inheritedAttribute.description = matchingAttribute.description;
                inheritedAttribute.listOfValues =
                  matchingAttribute.listOfValues;
              }
            }

            inheritedAttributes.push(inheritedAttribute);
            setInheritedAttributeList(inheritedAttributes);

            //Only display inherited attributes that are not overriden
            var matchingAttribute = attributes.find(
              (attr) => attr.objectName === inheritedAttribute.objectName
            );
            if (!matchingAttribute) {
              displayedAttributes.push(inheritedAttribute);
            }
          }
        }

        setAttributeList(displayedAttributes);

        rsltAttributeList = null;

        //Set the attributes that can be added (ie attributes that are not already displayed)
        let addAttributes = [];
        for (var allAttribute of allAttributes) {
          var matchingAttribute = displayedAttributes.find(
            (attr) => attr.objectName === allAttribute.objectName
          );
          const v_path = localStorage.getItem('v_path') || '/';
          if (!matchingAttribute) {
            //filter based on groups
            if (v_path.includes(allAttribute.objectGroup)) {
              addAttributes.push(allAttribute);
            }
          }
        }
        setAddAttributeList(addAttributes);
      });

    console.log('getAttributeList(): End');
  };

  const handleAttributeRowUpdate = (attribute, reset) => {
    console.log('handleAttributeRowUpdate(): Start');

    var _newObjectValue = null;

    //validation
    let errorList = [];
    if (attribute.objectName === '') {
      errorList.push('Please enter objectName');
    }
    if (attribute.objectValue === '') {
      errorList.push('Please enter objectValue');
    }

    v_path = localStorage.getItem('v_path');
    v_objectName = localStorage.getItem('v_objectName');
    v_objectParent = localStorage.getItem('v_objectParent');
    v_objectDescription = localStorage.getItem('v_objectDescription');

    switch (attribute.objectType) {
      case 'boolean': {
        console.log('handleAttributeRowUpdate() objectType: boolean');
        _newObjectValue =
          typeof attribute.objectValue === 'string' ||
          attribute.objectValue instanceof String
            ? attribute.objectValue.toLowerCase() === 'true'
              ? true
              : false
            : attribute.objectValue;
        break;
      }
      case 'number': {
        console.log('handleAttributeRowUpdate() objectType: number');
        _newObjectValue = Number(attribute.objectValue);
        break;
      }
      case 'date': {
        console.log('handleAttributeRowUpdate() objectType: date');
        _newObjectValue = format(attribute.objectValue, 'MM/dd/yyyy');
        break;
      }
      default: {
        console.log(
          'handleAttributeRowUpdate() objectType:' + attribute.objectType
        );
        _newObjectValue = attribute.objectValue;
      }
    }

    let newAttributeList = [];
    let matchingAttribute = attributeList.find(
      (attr) => attr.objectName === attribute.objectName
    );
    if (matchingAttribute) {
      //We're updating an attribute
      if (matchingAttribute.objectInherited) {
        //A NEW non-inherited attribute must be added, and the inherited one must be hidden
        //(only the NEW non-inherited attribute must be displayed)
        attribute.objectInherited = false;

        newAttributeList = [
          ...attributeList.filter(
            (attr) => attr.objectName !== attribute.objectName
          ),
          attribute,
        ];

        setAttributeList(newAttributeList);
      } else {
        matchingAttribute.objectValue = _newObjectValue;
        attribute = matchingAttribute;

        newAttributeList = attributeList;
      }
    } else {
      //We're adding an attribute
      attribute.objectInherited = false;

      newAttributeList = [...attributeList, attribute];

      setAttributeList(newAttributeList);

      //Remove the attribute from the LOV table
      setAddAttributeList(
        addAttributeList.filter(
          (attr) => attr.objectName !== attribute.objectName
        )
      );
    }

    console.log(
      'handleAttributeRowUpdate(): newData:' + JSON.stringify(attribute)
    );
    console.log('handleAttributeRowUpdate(): v_path:' + v_path);

    v_changeType = localStorage.getItem('v_changeType');
    switch (v_changeType) {
      case 'Add': {
        v_changeType = 'Added';

        v_changeReason = v_changeType.concat(
          ' attributeName: ',
          attribute.objectName,
          ', attributeValue: ',
          attribute.objectValue,
          ' => for objectName: ',
          v_path,
          ' & attributeGroup: ',
          attribute.objectGroup,
          '.'
        );

        v_attributesbkp['objectName'] = attribute.objectName;
        v_attributesbkp['objectValue'] = attribute.objectValue;
        break;
      }
      default: {
        v_changeType = 'Updated';

        v_prevAttributeData = JSON.parse(
          localStorage.getItem('v_prevAttributeData')
        );

        v_changeReason = v_changeType.concat(
          ' attributeName: ',
          attribute.objectName,
          ', attributeValue: ',
          attribute.objectValue,
          ' => for objectName: ',
          v_path,
          ' & attributeGroup: ',
          attribute.objectGroup,
          '. Previous attributeValue: ',
          v_prevAttributeData.objectValue
        );

        v_attributesbkp['objectName'] = v_prevAttributeData.objectName;
        v_attributesbkp['objectValue'] = v_prevAttributeData.objectValue;
      }
    }

    localStorage.setItem('v_attributesbkp', JSON.stringify(v_attributesbkp));
    localStorage.setItem('v_changeReason', v_changeReason);

    //navigate('/createAudit');

    if (errorList.length < 1) {
      //Build the JSON attributes
      let attributes = {};
      for (var nonInheritedAttribute of newAttributeList.filter(
        (attr) => !attr.objectInherited
      )) {
        attributes[nonInheritedAttribute.objectName] =
          nonInheritedAttribute.objectValue;
      }

      console.log(
        'handleAttributeRowUpdate(): attributes to save' + attributes
      );

      connectEA_API()
        .put('/configuration/object', {
          path: v_path,
          parent: v_objectParent,
          name: v_objectName,
          description: v_objectDescription,
          attributes: attributes,
        })
        .then((res) => {
          console.log('handleAttributeRowUpdate(): Inside API.put() Start');
        })
        .catch((error) => {
          setErrorMessages(['Update failed! Server error']);
          setIserror(true);
          //resolve()
        });
    } else {
      setErrorMessages(errorList);
      setIserror(true);
      // resolve()
    }

    console.log('handleAttributeRowUpdate(): End');
  };

  const handleAttributeRowDelete = (oldAttribute, resolve) => {
    console.log('handleAttributeRowDelete(): Start');

    v_path = localStorage.getItem('v_path');
    v_objectName = localStorage.getItem('v_objectName');
    v_objectParent = localStorage.getItem('v_objectParent');
    v_objectDescription = localStorage.getItem('v_objectDescription');

    let newAttributeList = [];
    let matchingInheritedAttribute = inheritedAttributeList.find(
      (attr) => attr.objectName === oldAttribute.objectName
    );
    if (matchingInheritedAttribute) {
      //An inherited attribute existed: remove the non-inherited one and show the inherited one
      newAttributeList = [
        ...attributeList.filter(
          (attr) => attr.objectName !== oldAttribute.objectName
        ),
        matchingInheritedAttribute,
      ];
      setAttributeList(newAttributeList);
    } else {
      //Remove the attribute from the list
      newAttributeList = attributeList.filter(
        (attr) => attr.objectName !== oldAttribute.objectName
      );
      setAttributeList(newAttributeList);

      //Add oldAttribute (the deleted attribute) to the list of attributes that can now be added
      setAddAttributeList([...addAttributeList, oldAttribute]);
    }

    console.log(
      'handleAttributeRowDelete(): newData:' + JSON.stringify(oldAttribute)
    );
    console.log('handleAttributeRowDelete(): v_path:' + v_path);

    v_changeReason = 'Deleted attributeName:'.concat(
      oldAttribute.objectName,
      ', attributeValue: ',
      oldAttribute.objectValue,
      ' => for objectName: ',
      v_path,
      ' & attributeGroup: ',
      oldAttribute.objectGroup,
      '.'
    );

    localStorage.setItem('v_changeReason', v_changeReason);
    v_attributesbkp['objectName'] = oldAttribute.objectName;
    v_attributesbkp['objectValue'] = oldAttribute.objectValue;

    localStorage.setItem('v_attributesbkp', JSON.stringify(v_attributesbkp));
    localStorage.setItem('v_changeType', 'Delete');

    //navigate('/createAudit');

    //Build the JSON attributes
    let attributes = {};
    for (var nonInheritedAttribute of newAttributeList.filter(
      (attr) => !attr.objectInherited
    )) {
      attributes[nonInheritedAttribute.objectName] =
        nonInheritedAttribute.objectValue;
    }

    console.log('handleAttributeRowDelete(): attributes to save' + attributes);

    connectEA_API()
      .get('/configuration/object', {
        body: {
          path: v_path,
          parent: v_objectParent,
          name: v_objectName,
          description: v_objectDescription,
          attributes: attributes,
        },
      })
      .then((res) => {
        console.log('handleAttributeRowDelete(): Inside API.put() Start');
      })
      .catch((error) => {
        setErrorMessages(['Update failed! Server error']);
        setIserror(true);
        //resolve()
      });

    console.log('handleAttributeRowDelete(): End');
  };

  const handleEditAttributePopup = (item) => {
    console.log('handleEditAttributePopup(): Start');
    console.log('handleEditAttributePopup(): item:' + JSON.stringify(item));

    v_prevAttributeData.objectName = item.objectName;
    v_prevAttributeData.objectValue = item.objectValue;
    localStorage.setItem(
      'v_prevAttributeData',
      JSON.stringify(v_prevAttributeData)
    );

    setRecordForEdit(item);
    console.log('handleEditAttributePopup(): setRecordForEdit');

    setOpenPopup(true);
    setOpenAddAttributePopup(false);
    console.log('handleEditAttributePopup(): setOpenPopup');
    console.log('handleEditAttributePopup(): End');
  };

  const addOrEdit = (attribute, resetForm, resolve) => {
    console.log('addOrEdit(): Start');
    console.log('addOrEdit(): attribute:' + JSON.stringify(attribute));
    if (attribute.id === 0) {
      attributeService.insertAttribute(attribute);
    } else {
      attributeService.updateAttribute(attribute);
    }

    resetForm();
    setRecordForEdit(null);
    setOpenPopup(false);

    handleAttributeRowUpdate(attribute, resolve);

    setRecords(attributeService.getAllAttributes());
  };

  const actions = [
    {
      icon: () => <Edit />,
      tooltip: 'Edit Attribute',
      onClick: (_, rowData) =>
        new Promise((resolve) => {
          localStorage.setItem('v_changeType', 'Update');
          handleEditAttributePopup(rowData, resolve);
        }),
    },
    (rowData) => ({
      icon: () => <DeleteOutline />,
      tooltip:
        //:rowData.accessControl === 'basic'
        //? 'Cannot Delete Attribute (basic access control)'
        rowData.objectInherited
          ? 'Cannot Delete Attribute (attribute inherited)'
          : 'Delete Attribute',
      onClick: (_, oldData) => {
        handleAttributeRowDelete(oldData);
      },
      //disabled: rowData.accessControl === 'basic' || rowData.objectInherited
      disabled: rowData.objectInherited,
    }),
  ];

  const addActions = [
    (rowData) => ({
      icon: () => <AddBox />,
      tooltip: 'Add Attribute',
      // disabled: rowData.accessControl === 'basic',
      onClick: (_, rowData) =>
        new Promise((resolve) => {
          localStorage.setItem('v_changeType', 'Add');
          handleEditAttributePopup(rowData, resolve);
        }),
    }),
  ];

  return (
    <div>
      <MuiButton
        variant={'contained'}
        size={'large'}
        onClick={() => setOpenPopupAddItemToParent(true)}
        style={{ marginTop: '10px', marginBottom: '10px' }}
      >
        Add Item
      </MuiButton>
      <div>
        <MaterialTable
          title={updateTitle}
          pageSize={10}
          columns={attributeListColumns}
          data={attributeList}
          icons={tableIcons}
          actions={actions}
          options={{
            actionsColumnIndex: -1,
            grouping: true,
            exportButton: true,
            //filtering: true,
            search: true,
            sorting: true,
            headerStyle: {
              backgroundColor: '#536a82',
              color: '#FFF',
            },
          }}
        />
      </div>

      <br />
      <Accordion
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography variant="h6">{addTitle}</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <MaterialTable
              title=""
              pageSize={10}
              columns={addAttributeListColumns}
              data={addAttributeList}
              icons={tableIcons}
              actions={addActions}
              options={{
                actionsColumnIndex: -1,
                grouping: true,
                //filtering: true,
                sorting: true,
                search: true,
                headerStyle: {
                  backgroundColor: '#536a82',
                  color: '#FFF',
                },
              }}
            />
          </div>
        </AccordionDetails>
      </Accordion>

      <br />
      <div>
        <Popup
          title="Attribute Form"
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
        >
          <EditAttributeForm
            recordForEdit={recordForEdit}
            addOrEdit={addOrEdit}
          />
        </Popup>
      </div>

      <div>
        <Popup
          title="Add Item"
          openPopup={openPopupAddItemToParent}
          setOpenPopup={setOpenPopupAddItemToParent}
        >
          <AddItemForm />
        </Popup>
      </div>
    </div>
  );
}

export default ConfigurationObjects;
